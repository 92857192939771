<template>
  <a-modal
    title="审核"
    :width="700"
    :visible="visible"
    :confirmLoading="confirmLoading"
    :destroyOnClose="true"
    @ok="handleSubmit"
    @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <a-row :gutter="24">
        <a-col :md="24" :sm="24">
          <a-form :form="form">
            <!-- 退款类型 -->
            <a-form-item label="退款类型" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
              <a-select 
                v-decorator="['refundType', { rules: [{ required: true, message: '请选择！' }] }]"
                allow-clear 
                placeholder="请选择"
                @change="changeRefundType"
                disabled
              >
                <a-select-option value="1">退款</a-select-option>
                <a-select-option value="2">退货退款</a-select-option>
              </a-select>
            </a-form-item>
            <!-- 审核（同意 or 拒绝） -->
            <a-form-item label="审核" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
              <a-radio-group 
                name="radioGroup" 
                v-decorator="['status', { rules: [{ required: true, message: '请选择！' }] }]"
              >
                <a-radio :value="2"> 
                  同意 
                </a-radio>
                <a-radio :value="form.getFieldValue('refundType')==1?3:form.getFieldValue('refundType')==2?5:''"> 
                  拒绝&nbsp;&nbsp;&nbsp; 
                </a-radio>
              </a-radio-group>
            </a-form-item>
            <!-- 消息内容 -->
            <a-form-item
               v-if="form.getFieldValue('status')!=2"
               label="拒绝原因" 
               :labelCol="labelCol" 
               :wrapperCol="wrapperCol" 
               has-feedback
            >
              <a-textarea
                v-decorator="['refuseReason', { rules: [{ required: true, message: '请输入！' }] }]"
                placeholder="请输入消息内容"
              />
            </a-form-item>
          </a-form>
        </a-col>
      </a-row>
    </a-spin>
  </a-modal>
</template>

<script>
import { 
  refundAudit,
  refundDetail
} from '@/api/modular/mallLiving/refund'

export default {
  data() {
    return {
      loading: false,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      pictureLoading:false,
      picture:'',
      id:'',
      searchList:[] //搜索框的数组
    }
  },

  methods: {
    // 初始化方法
    audit(record) {
      this.visible = true
      this.id = record.id
      // 调详情接口
      refundDetail({ id: record.id }).then((res)=>{
        if(res.success){
          let details = res.data
          setTimeout(()=>{
            this.form.setFieldsValue({
              refundType: details.refundType,
            })
          }, 200)
        }
      })
    },
    handleSubmit() {
      this.confirmLoading = true
      this.form.validateFields((errors, values) => {
        if (!errors) {
            refundAudit({id: this.id, ...values}).then((res) => {
              if(res.success){
                this.$message.success("审核成功！")
                this.$emit("ok",values)
                this.confirmLoading = false
                this.handleCancel()
              }else{
                setTimeout(()=>{
                  this.confirmLoading = false
                }, 600)
              }
            })
        } else {
          this.confirmLoading = false
        }
      })
    },
    // 切换选项后重置status，在这里没卵用，但还是写上吧
    changeRefundType(){
      this.form.setFieldsValue({
        status:undefined
      })
    },
    handleCancel() {
      this.visible = false //关闭对话框
      this.confirmLoading = false  //关闭页面加载状态
      this.form.resetFields() //重置表单
    }
  },
}
</script>
<style scoped>
.flex {
  display: flex;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>